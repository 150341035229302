import { useEffect, useState } from 'react'
import BucketSvgIcon from '../../../components/BucketAsset/BucketSvgAsset/BucketSvgIcon'
import useDeleteFavouriteVehicleMutation from '../../../hooks/useDeleteFavouriteVehicleMutation'
import useSaveFavouriteVehicleMutation from '../../../hooks/useSaveFavouriteVehicleMutation'
import useIsMobile from '../../../hooks/useIsMobile'
import classNames from 'classnames'

export default function VehicleFavourite({
  id,
  onDelete,
  favouriteVehicleIds,
  disabled,
  className,
}) {
  const isMobile = useIsMobile()
  const { saveToFavourites } = useSaveFavouriteVehicleMutation()
  const { deleteFromFavourites } = useDeleteFavouriteVehicleMutation()
  const [favourite, setfavourite] = useState(false)

  useEffect(() => {
    if (favouriteVehicleIds?.includes(id)) {
      setfavourite(true)
    }
  }, [favouriteVehicleIds])

  const switchFavourite = () => {
    setfavourite((oldFavourite) => {
      if (!oldFavourite) {
        saveToFavourites(id)
      } else {
        deleteFromFavourites(id).then(() => {
          if (onDelete) {
            onDelete(id)
          }
        })
      }

      return !oldFavourite
    })
  }

  let srcIconName

  if (disabled) {
    srcIconName = 'fi-rr-trash'
  } else if (favourite) {
    srcIconName = 'heart-red'
  } else {
    srcIconName = 'heart-outline'
  }

  return (
    <div
      className={
        className?.length
          ? className
          : classNames(
              'cursor-pointer absolute z-10 bg-white rounded-full p-2 m-2.5',
              isMobile ? '' : 'right-0'
            )
      }
      onClick={switchFavourite}
    >
      <BucketSvgIcon src={srcIconName} className="text-black h-6 w-6" />
    </div>
  )
}
