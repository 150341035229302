import {
  INTERNAL_ROUTES,
  getVehicleDetailsRoute,
} from '../../util/internalRoutes'
import { useTranslation } from 'react-i18next'
import TitleLayout from '../../layouts/TitleLayout/TitleLayout'
import get from 'lodash/get'
import VehicleFavourite from '../SRP/components/VehicleFavourite'
import { Link } from 'react-router-dom'
import {
  AUTOMATION_IDS,
  BUTTON_COLOR_TYPES,
  VEHICLE_STATUS,
} from '../../util/constants'
import VehicleCard from '../SRP/components/VehicleCard'
import classNames from 'classnames'
import useLocalFilterQuery from '../../hooks/useLocalFilterQuery'
import { useEffect, useState } from 'react'
import useFavouriteVehicleIds, {
  useFavouriteVehicles,
} from '../../hooks/useFavouriteVehicles'
import { Spinner } from 'asb-frontend'
import Button from '../../components/Button/Button'
import useCustomNavigate from '../../hooks/useCustomNavigate'
import useAppState from '../../hooks/useAppState'
import useBuildUrl from '../../hooks/useBuildUrl'
import { tagManagerCarListSelect } from '../../tracking/trackingSRP'
import useSubscriber from '../../hooks/useSubscriber'
import Container from '../../components/Container/Container'

const spinnerSize = 28

export default function FavouritesPage() {
  const { vehicles, loading, loadVehicles } = useFavouriteVehicles()
  const { t } = useTranslation('ui')
  const buildUrl = useBuildUrl()
  const { favouriteVehicleIds } = useFavouriteVehicleIds()
  const navigate = useCustomNavigate()
  const { isLoggedIn } = useAppState()
  const { data } = useSubscriber()
  const [originOf, setOriginOf] = useState(undefined)
  const currentSubscriberData = data?.currentSubscriber
  const latitude = get(vehicles, 'filters.latitude')
  const longitude = get(vehicles, 'filters.longitude')
  const unit = get(vehicles, 'filters.distanceUnit')
  const [deletedItems] = useState([])

  const { data: filtersResult } = useLocalFilterQuery()
  const filter = { duration: get(filtersResult, 'filters.duration') }

  useEffect(() => {
    loadVehicles()
  }, [])

  const getVehiclePrice = (vehicle) => {
    if (!vehicle.pricing) return
    const vehiclePricing = vehicle.pricing?.find(
      (p) => p.duration === filter.duration
    ) ||
      vehicle.pricing[0] || { durationUnit: '' }
    return vehiclePricing?.bestPrice
  }

  const sendVehicleTrack = (event, index, vehicle) => {
    tagManagerCarListSelect(
      currentSubscriberData,
      vehicle,
      event.currentTarget.id,
      index
    )
  }

  useEffect(() => {
    if (latitude && longitude) {
      setOriginOf({
        latitude: latitude,
        longitude: longitude,
        unit,
      })
    }
  }, [latitude, longitude, unit])

  const getVehicles = () =>
    vehicles?.filter((item) => !deletedItems.includes(item.id)) || []

  const isDisabled = (vehicle) => {
    return vehicle?.status !== VEHICLE_STATUS.AVAILABLE
  }

  return (
    <TitleLayout title={t('myFavourites')} backTo={INTERNAL_ROUTES.SRP}>
      <div className="md:w-limited mx-4 md:mx-auto mb-20 mt-5 md:mt-10 text-center">
        {loading && <Spinner size={spinnerSize} />}
        {!loading && !vehicles?.length && (
          <div className="flex flex-col w-full p-4 mt-2">
            <p className="text-2xl font-light mx-2 text-center">
              {t('favouritesEmpty')}
            </p>
            <p className="text-lg font-light mx-2 mb-2 mt-4 text-center">
              {t('browserAndSaveFavourites')}
            </p>
            <Button
              color={BUTTON_COLOR_TYPES.primary}
              className="justify-center mx-2 my-1"
              onClick={() => navigate(INTERNAL_ROUTES.SRP)}
            >
              {t('discoverAllTheCars')}
            </Button>
          </div>
        )}

        {!isLoggedIn && !loading && vehicles?.length > 0 && (
          <div className="flex w-full flex-col mb-4 py-4 px-2 border-b-2 border-gray-light-2">
            <p className="mx-2 text-center mb-4 text-2xl">
              {t('favouritesInOneClick')}
            </p>
            <p className="mx-2 text-center mb-4 font-light">
              {t('favouritesLogInToSee')}
            </p>
            <Button
              className="justify-center mx-2 my-1"
              onClick={() => navigate(INTERNAL_ROUTES.LOGIN)}
            >
              {t('login')}
            </Button>

            <Button
              color={BUTTON_COLOR_TYPES.primaryLight}
              className="justify-center mx-2 my-1"
              onClick={() => navigate(INTERNAL_ROUTES.REGISTER)}
            >
              {t('signUp')}
            </Button>
          </div>
        )}

        {!loading && getVehicles()?.length > 0 && (
          <div className="p-4">
            {getVehicles()?.length}{' '}
            {getVehicles()?.length === 1 ? t('carSaved') : t('carsSaved')}
          </div>
        )}
      </div>

      <Container>
        <div className="py-6">
          <div
            className={'grid gap-6 md:grid-cols-2 lg:grid-cols-3'}
            dataTestId={AUTOMATION_IDS.availableVehiclesList}
          >
            {!loading &&
              getVehicles().map((vehicle, index) => (
                <div
                  className="relative cursor-pointer overflow-hidden rounded-lg shadow-card"
                  key={vehicle.id}
                >
                  <VehicleFavourite
                    id={vehicle.id}
                    favouriteVehicleIds={favouriteVehicleIds}
                  />
                  <Link
                    dataTestId={`${AUTOMATION_IDS.availableVehicle}-${index}`}
                    key={vehicle.id}
                    id={`VE_${index}`}
                    to={buildUrl(getVehicleDetailsRoute(vehicle))}
                    onClick={(event) => {
                      sendVehicleTrack(event, index, vehicle)
                    }}
                  >
                    <VehicleCard
                      className={classNames(
                        'h-full srp-vehicle-card md:flex-col vehicle-card-without-map'
                      )}
                      vehicleImageClassName={classNames('w-5/12 md:w-full')}
                      price={getVehiclePrice(vehicle)}
                      hideSortDetails
                      showFromPriceText
                      excludeCents
                      originOf={originOf}
                      showNotAvailable={isDisabled(vehicle)}
                      vehicle={vehicle}
                    />
                  </Link>
                </div>
              ))}
          </div>
        </div>
      </Container>
    </TitleLayout>
  )
}
