import { Capacitor } from '@capacitor/core'
import { Device } from '@capacitor/device';
import { EMPTY_STRING, MAX_MOBILE_WIDTH, PLATFORMS } from '../constants'

export function isNativePlatform() {
  return Capacitor.isNativePlatform()
}

export function isMobileOrNativePlatform() {
  return Capacitor.isNativePlatform() || window.innerWidth <= MAX_MOBILE_WIDTH
}

export function getPlatform() {
  return Capacitor.getPlatform()
}

export function isNativeAndroid() {
  return isNativePlatform() && getPlatform() === PLATFORMS.android
}

export function isNativeiOS() {
  return isNativePlatform() && getPlatform() === PLATFORMS.ios
}

export async function getOperatingSystem(){
  try {
    const deviceInfo = await Device.getInfo()
    return deviceInfo?.operatingSystem
  } catch (error) {
    return EMPTY_STRING
  }
}
