import { SafeArea } from 'capacitor-plugin-safe-area'
import { useEffect, useState } from 'react'
import { isNativeiOS } from '../util/native/nativeUtils'

export function useSafeAreaMobile() {
  const [statusBarHeight, setStatusBarHeight] = useState(0)

  useEffect(() => {
    if (isNativeiOS()) {
      SafeArea.getStatusBarHeight().then(({ statusBarHeight }) => {
        setStatusBarHeight(statusBarHeight)
      })
    }
  }, [])

  return { statusBarHeight }
}
