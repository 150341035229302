import { AppConfig } from '../environment'

export const STRIPE_CHECKOUT_REDIRECT_PATH_KEY =
  'STRIPE_CHECKOUT_REDIRECT_PATH_KEY'

export const REGISTRATION_SCHEMA = 1
export const CONFIRM_IDENTITY_SCHEMA = 2

export const ALPHANUMERIC_TWELVE_VALIDATION = 'alphanumeric12'

export const VEHICLE_PROFILE = 'profile'

export const MAX_BODY_LENGTH_EXCEEDED = 'ERR_FR_MAX_BODY_LENGTH_EXCEEDED'

export const NIE = 'nie'
export const DNI = 'dni'

export const MAX_MOBILE_WIDTH = 768

export const COMMON_REGISTRATION_FORM_CLASS = 'registrationFormClass'
export const COMMON_CONFIRM_IDENTITY_FORM_CLASS = 'confirmIdentityFormClass'

export const IMAGE_SIZE = 8388608

export const TRACKING_TRANSPARENCY_STATUS = {
  authorized: 'authorized',
  notDetermined: 'notDetermined',
  denied: 'denied',
  restricted: 'restricted',
}

export const HTTP_PROTOCOL = 'https://'

export const eventKeys = {
  modalToggle: 'modalToggle',
}

export const TRANSLATIONS = {
  UI: 'ui',
  CHECKS: 'checks',
  URLS: 'urls',
  WEBPAGE_TITLES: 'webpageTitles',
  WEBPAGE_DESCRIPTIONS: 'webpageDescriptions',
  FUEL_TYPES: 'fuelTypes',
  GEAR_SHIFT: 'gearShift',
  SHARING: 'sharing',
  ERROR_CODES: 'errorCodes',
  CREDIT_CHECK: 'creditCheck',
  SUBSCRIPTION_CHECKS: 'subscriptionChecks',
  COMMUNICATION_CHANNELS: 'communicationChannels',
}

export const GENDERS = [
  { label: 'male', value: 'MALE' },
  { label: 'female', value: 'FEMALE' },
  { label: 'preferNotAnswer', value: 'UNKNOWN' },
]

export const NONE = 'none'

export const SUBSCRIBER_STATUS = {
  APPROVED: 'APPROVED',
  SIGNED_UP: 'SIGNED_UP',
  SIGN_UP_REQUEST: 'SIGN_UP_REQUEST',
  SIGN_UP_DECLINED: 'SIGN_DECLINED',
  PENDING: 'PENDING',
  SUSPENDED: 'SUSPENDED',
  DECLINED: 'DECLINED',
  MANUAL_REVIEW_REQUIRED: 'MANUAL-REVIEW-REQUIRED',
  NOT_LOGGED: 'NOT_LOGGED',
}

export const SUBSCRIPTION_STATUS = {
  ACTIVE: 'ACTIVE',
  CLOSE: 'CLOSED',
  PENDING: 'PENDING',
  CANCELED: 'CANCELED',
  PENDING_PAYMENT_AUTHENTICATION: 'PENDING_PAYMENT_AUTHENTICATION',
  PAYMENT_FAILED: 'PAYMENT_FAILED',
  CANCELED_BY_DEALER: 'CANCELED_BY_DEALER',
}

export const VEHICLE_STATUS = {
  AVAILABLE: 'available',
}

export const PAGE_ID = {
  VDP: 'vdp',
}

export const FIREBASE_PERMISSIONS = {
  denied: 'denied',
}

export const APP_UPDATE_TIMEOUT = 5000
export const APP_UPDATE_TIMEOUT_ERROR = 'app_update_check_timed_out'

export const LENGTH_EMPTY = 0
export const LENGTH_ONE_ELEMENT = 1

export const VALUE_ZERO = 0
export const VALUE_ONE = 1

export const DEFAULT_MIN_AGE = 25

export const DRIVER_LICENSE_FILE_NAME = {
  front: 'driverLicenseFront.png',
  back: 'driverLicenseBack.png',
}

export const CLOSE_CAROUSEL_OPTIONS = {
  default: 'default',
  fromCta: 'fromCta',
}

export const BULLET_POSITION = '02'

export const ID_FILE_NAME = {
  front: 'idFront.png',
  back: 'idBack.png',
}

export const UPLOAD_CONFIRM_IDENTITY_DOCUMENT_TYPES = {
  id: 'id',
  driverLicense: 'driverLicense',
}

export const DIGITAL_SIGNATURE_EID = 'ELECTRONIC_ID'

export const DOCUMENT_AUTHENTICATION_PROVIDERS = {
  electronicId: 'ELECTRONIC_ID',
  electronicIdHigh: 'ELECTRONIC_ID_HIGH',
  fileUpload: 'FILE_UPLOAD',
  bankId: 'BANK_ID',
  skip: 'SKIP',
}

export const BANKID_OPTIONS = {
  default: 'default',
  desktop: 'desktop',
  mobile: 'mobile',
}

export const BANK_ID_STATUS = {
  complete: 'complete',
  error: 'error',
  notEligibleByAge: 'notEligibleByAge',
}

export const DOCUMENT_AUTHENTICATION_STATUS = {
  completed: 'completed',
  pending: 'pending',
  rejected: 'rejected',
}

export const CREDIT_CHECK_STATUS = {
  APPROVED: 'APPROVED',
}

export const SETUP_DELAY_LIMIT = 2
export const HOURS_DAY = 24

export const TYPESDOCUMENTSUPLOAD = {
  image: 'image',
  pdf: 'pdf',
}

export const CONFIRM_IDENTITY_FIELD_NAMES = {
  firstName: 'firstName',
  firstLastName: 'firstLastName',
  secondLastName: 'secondLastName',
  fiscalCode: 'fiscalCode',
  gender: 'gender',
  address: 'address',
  city: 'city',
  state: 'state',
  province: 'province',
  common: 'common',
  zip: 'zip',
  driversLicenseNumber: 'driversLicenseNumber',
  driversLicenseExpDate: 'driversLicenseExpDate',
  driversLicenseCategories: 'driversLicenseCategories',
  documentPdf: 'documentPdfFile',
  documentFrontFile: 'documentFrontFile',
  documentBackFile: 'documentBackFile',
  documentIdNumber: 'documentIdNumber',
  documentIdType: 'documentIdType',
  documentIssuer: 'documentIssuer',
  birthDate: 'birthDate',
  identityDocumentExpDate: 'identityDocumentExpDate',
  email: 'email',
  country: 'country',
  phoneNumber: 'phoneNumber',
}

export const DRIVER_LICENSE_CATEGORIES = Object.freeze([
  'AM',
  'A1',
  'A2',
  'A',
  'B',
  'B96',
  'B+E',
])

export const RESET_PASSWORD_FIELD_NAMES = {
  email: 'email',
  code: 'code',
  password: 'password',
  confirmPassword: 'confirmPassword',
}

export const LOGIN_FIELD_NAMES = {
  email: 'email',
  password: 'password',
  rememberMe: 'rememberMe',
}

export const REGISTER_FIELD_NAMES = {
  firstName: 'firstName',
  firstLastName: 'firstLastName',
  secondLastName: 'secondLastName',
  email: 'email',
  password: 'password',
  phoneNumber: 'phoneNumber',
  privacyPolicyEID: 'privacyPolicyEID',
  mobilityConsent: 'mobilityConsent',
  sendMeUpdate: 'sendMeUpdate',
}

export const PHONE_NUMBER_ERROR_TYPES = {
  validation: 'validate',
  required: 'required',
}

export const LANGUAGE_CHANGED_ON_POPUP_EVENT = 'languageChangedOnPopup'
export const NEED_TO_CHANGE_LANGUAGE_EVENT = 'needToChangeLanguage'

export const FETCH_POLICIES = {
  networkOnly: 'network-only',
  noCache: 'no-cache',
}

export const BUTTON_CLASSES = {
  primary: 'btn-primary',
  secondary: 'btn-secondary',
  cancel: 'btn-cancel',
}

export const BUTTON_COLOR_TYPES = {
  primary: 'primary',
  disabled: 'disabled',
  primaryLight: 'primary-light',
  secondary: 'secondary',
  transparent: 'transparent',
  error: 'error',
  dropdown: 'dropdown',
  gray_light_2: 'gray_light_2',
  primaryReverse: 'primary-reverse',
}

export const VALUES = {
  dash: '-',
  comma: ',',
  slash: '/',
  empty: '',
  space: ' ',
  colon: ':',
  questionMark: '?',
  lineBreak: '\n',
  sharp: '#',
  pipe: '|',
  singleQuoteMark: "'",
}

export const HTML_AUTOCOMPLETE_OFF = 'off'

export const TIME_IN_SECONDS_TO_REQUEST_NEW_CODE = 30

export const CONFIRM_EMAIL_FIELD_NAMES = {
  emailValidationCode: 'emailValidationCode',
}

export const EID_VALIDATE_PHONE_FIELD_NAMES = {
  phoneValidationCode: 'phoneValidationCode',
}

export const CHECKS = {
  MANDATORY: 'mandatory',
  NOT_MANDATORY: 'not_mandatory',
  LABEL_TYPE_TEXT: 'text',
  LABEL_TYPE_LINK: 'link',
}

export const FAVICON = 'favicon'
export const ASSET_TYPES = { icon: 'icons', image: 'images' }
export const VERSION = 'version'
export const MANIFEST = 'webappManifest.json'
export const LOCAL_BUCKET_URL = AppConfig.PUBLIC_PATH + 'assets'

export const EMPTY_PNG =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAAtJREFUGFdjYAACAAAFAAGq1chRAAAAAElFTkSuQmCC'

export const PRICING_CATEGORIES = {
  SUBSCRIPTION_FEE: 'SUBSCRIPTION_FEE',
  DEPOSIT: 'DEPOSIT',
  FEE: 'FEE',
  TAX: 'TAX',
  TAXES_AND_FEES: 'TAXES_AND_FEES',
}

export const PAYMENT_STATUS = {
  SUCCEEDED: 'SUCCEEDED',
}

export const FUEL_TYPES = {
  GASOLINE: 'Gasoline',
  ELECTRIC: 'Electric',
  HYBRID: 'Hybrid',
  DIESEL: 'Diesel',
  HYDROGEN: 'Hydrogen',
  LIQUEFIED_PETROL_GAS: 'LiquefiedPetroleumGas',
  METHANOL: 'Methanol',
  SUPER: 'Super',
  COMPRESSED_NATURAL_GAS: 'CompressedNaturalGas',
  ETHANOL: 'Ethanol',
  FLEXIBLE_FUEL: 'FlexibleFuel',
}

export const Apps = {
  WEBAPP: 'webapp',
}

export const TenantCustomHeaders = {
  APP: 'x-tenant-app',
}

export const QUERY_PARAM_VALUE_ANY = 'any'

export const QUERY_PARAM_NAMES = {
  filters: {
    postalCode: 'postal-code',
    distance: 'distance',
    availableNow: 'available-now',
    maxPrice: 'max-price',
    minPrice: 'min-price',
    makesAndModels: 'models',
    bodyStyles: 'body-styles',
    dealers: 'dealers',
    fuelTypes: 'fuel-types',
    transmission: 'transmission',
  },
  assetVersion: 'version',
  hourstamp: 'hourstamp',
}

export const REQUIRED = 'required'

export const MENU_MODAL_OPEN = 'modal-open'

export const CACHE_KEYS = {
  storedRequests: 'STORED_REQUESTS',
  vehicleFilters: 'VEHICLE_FILTERS',
  manifestVersion: 'manifestVersion',
  fallbackManifestVersion: 'fallbackManifestVersion',
  checkoutId: 'CHECKOUT_ID',
  config: 'config',
  translations: 'translations',
  redirectUrl: 'redirectUrl',
}

export const TOAST_ERROR_DURATION = 7000
export const TOAST_ERROR_CLASSES = 'toast toast-error'
export const FILE_UPLOAD_LIMIT = 5
export const FILE_SIZE_UPLOAD_LIMIT = 2000000

export const SUBSCRIPTION_ESTIMATION_ERROR = 'Unable to estimate subscription'

export const EID_SCAN_URL = `/js/videoid-3.x/videoscan.js`
export const EID_VIDEO_URL = `/js/videoid-3.x/videoid.js`

export const ELECTRONIC_ID_DOC_TYPE = {
  driversLicense: 'DriverLicense',
  passport: 'Passport',
  idCard: 'IdCard',
}

export const CAMERA_QUALITY = 0.7
export const NATIVE_UPLOAD_MULTIPLE = true
export const NATIVE_UPLOAD_READ_DATA = true
export const NATIVE_UPLOAD_ALLOW_EDIT = false
export const NATIVE_IMAGE_PERMISSIONS = {
  permissions: ['camera', 'photos'],
}
export const PERMISSION_STATE_DENIED = 'denied'
export const UPLOAD_ACTION_SHEET_ACTIONS = {
  pickFiles: 0,
  pickImages: 1,
  getPhoto: 2,
}

export const MIN_EQUIPMENT_ITEMS = 6

export const COLON_SEPARATOR = ', '
export const DEALER_NAME_FIELD = 'name'
export const CENTS_DIV = 100

export const STRING_BOOLEAN = {
  true: 'true',
  false: 'false',
}

export const VEHICLE_LIST_INITIAL_PAGE = 1

export const SPAN_PREFIX = 'span_'
export const ASC = 'asc'
export const VALUE_TOTAL_PRICE = 'value.totalPrice'
export const ADDITIONAL_PRICE = 'additionalPrice'
export const PLUS_SYMBOL = '+'
export const EURO_SYMBOL = '€'

export const TOTAL_PERCENT = 100
export const FeeCategory = {
  DEPOSIT: 'deposit',
  MILEAGE: 'mileage',
  SUBSCRIPTION: 'subscription',
  MISC: 'misc',
  REFUND: 'refund',
}

export const FeeName = {
  FLAT: 'FLAT',
  FUEL_TYPE_FLAT: 'FUEL_TYPE_FLAT',
}

export const CHECK_PREFIX = 'check_'

export const EXTERNAL_LINK = 'external'
export const STATIC_LINK = 'static'
export const INTERNAL_LINK = 'internal'
export const HTML_ELEMENTS_IDS = {
  interactionStudio: {
    vehiclesGrid: 'istudio-vehicles-grid',
    footer: 'istudio-footer',
    header: 'istudio_header',
  },
  footer: {
    links: 'footer-links',
    apps: 'footer-apps',
  },
}

export const TOOLBAR_POSTFIX = '#toolbar=0'
export const PDF_EXTENSION = '.pdf'
export const HALF = 2
export const DOC_PREFIX_TAG = 'doc.'

export const EMPTY_STRING = ''
export const EMPTY_ARRAY = []
export const EMPTY_OBJECT = {}

export const LOCATION_FILTER_MAP_MIN_VIEW = 1024
export const LOCATION_FILTER_COORDINATES_ZOOM = 8

export const REGISTRATION_CHECKS = 'registrationChecks'
export const COMMUNICATION_CHANNELS = 'communicationChannels'

export const EMAIL = 'email'

export const PAGE_ASSET_NAMES = {
  web: 'web',
  android: 'app_android',
  ios: 'app_ios',
}

export const PLATFORMS = {
  web: 'web',
  android: 'android',
  ios: 'ios',
}

export const StatusCodes = {
  OK: 200,
}

export const STRING_DURATIONS = {
  month: 'month',
  week: 'week',
}

export const INTERNAL_ROUTES_KEYS = {
  LOGIN: 'LOGIN',
}

export const PAGE_EVENTS = {
  resize: 'resize',
  popstate: 'popstate',
  message: 'message',
}

export const PATH_SEPARATOR = '/'

export const AUTOMATION_IDS = {
  availabilitySwitchFilter: 'availability-switch-filter',
  bodyStyleOptions: 'bodyStyle_options',
  dropdownUpdateDocuments: 'dropdown_update_documents',
  fileImageInput: 'file_image_input',
  fuelTypeOptions: 'fuelType_options',
  gearShiftOtions: 'gearShift_options',
  _input: '_input',
  _option: '_option',
  _bodyStyle: '_bodyStyle',
  _button: 'button',
  _gearShift: 'gearShift',
  distance: 'distance',
  zipCode: 'zipCode',
  brand: 'brand',
  model: 'model',
  languageConfigButton: 'language-config-button',
  availableVehiclesList: 'available-vehicles-list',
  availableVehicle: 'available-vehicle',
  filtersButton: 'filters_button',
  closeButton: 'close_button',
  resetFiltersButton: 'reset_filters_button',
  applyFiltersButton: 'apply_filters_button',
  availableVehicleTitle: 'available-vehicle-title',
  vehicleDescription: 'vehicle_description',
  vehicleDetailsYear: 'vehicle_details_year',
  vehicleDetailsDistance: 'vehicle_details_distance',
  vehicleDetailsBrand: 'vehicle_details_brand',
  vehicleDetailsModel: 'vehicle_details_model',
  vehicleDetailsBodyStyle: 'vehicle_details_bodyStyle',
  vehicleDetailsDoors: 'vehicle_details_doors',
  vehicleDetailsExteriorColor: 'vehicle_details_exteriorColor',
  vehicleDetailsInteriorColor: 'vehicle_details_interiorColor',
  vehicleDetailsSeats: 'vehicle_details_seats',
  vehicleDetailsEngineSizeUnits: 'vehicle_details_engineSizeUnits',
  vehicleDetailsHorsePowerUnits: 'vehicle_details_horsePowerUnits',
  vehicleDetailsEngineKw: 'vehicle_details_engineKw',
  vehicleDetailsGearType: 'vehicle_details_gearType',
  vehicleDetailsFuelType: 'vehicle_details_fuelType',
  vehicleDetailsDriverType: 'vehicle_details_driveType',
  vehicleDetailsPetrolEmissions: 'vehicle_details_petrol_emissions',
  vehicleDetailsElectricEmissions: 'vehicle_details_electric_emissions',
  vehicleDetailsCo2Emissions: 'vehicle_details_co2_emissions',
  vehicleDetailsCo2Class: 'vehicle_details_co2_class',
  vehicleDetailsElectricRange: 'vehicle_details_electric_range',
  locationInfo: 'location_info',
  vehicleLabel: 'vehicle_label',
  vehicleFuelType: 'vehicle_fuelType',
  vehicleGearType: 'vehicle_gearType',
}

export const PERIOD_SYMBOL = '.'

export const BRAND_NAMES = {
  Ford: 'ford',
}

export const EXPERIAN_CHECK = 'experian'
export const VALID_CREDIT_CHECK_PROVIDERS = {
  dunsAndBradstreet: 'dunsAndBradstreet',
  experian: 'experian',
}

export const EXPERIAN_TOKEN_SESSION = '@experian-session'

export const REF_SUFFIX = '_ref'

export const HTML_BREAK_LINE = <br></br>

export const KYC_TAGMANAGER = {
  autobanking: 'Autobanking',
  document: 'Documentacion',
}

export const BUCKET_STYLE_SHEET_ID = 'bucket-style'
export const PORTAL_STYLE_SHEET_ID = 'portal-style'

export const ContentType = Object.freeze({
  TEXT_CSV: 'text/csv',
})

export const downloadAppTranslations = {
  googlePlay: 'downloadAppOnGooglePlay',
  appStore: 'downloadAppOnAppStore',
}
