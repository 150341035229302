import { get } from 'lodash'
import { gql, useQuery } from '@apollo/client'
import { useState } from 'react'
import useAppState from '../../hooks/useAppState'

const SUBSCRIPTION_ESTIMATE = gql`
  query SubscriptionEstimate(
    $vehicleId: ID!
    $pickupDate: Date!
    $duration: Int!
    $durationUnit: DurationUnit!
    $termMonths: Int
    $termMileage: Int
    $promotionalCodeId: ID
  ) {
    subscriptionEstimate(
      vehicleId: $vehicleId
      pickupDate: $pickupDate
      duration: $duration
      durationUnit: $durationUnit
      termMonths: $termMonths
      termMileage: $termMileage
      promotionalCodeId: $promotionalCodeId
      includeTaxesInFees: true
    ) {
      lineItems {
        name
        amount
        category
      }
      promotionalDiscount {
        amount
        appliedOn
      }
      total
    }
  }
`

const RENEWAL_ESTIMATE = gql`
  query RenewalEstimate(
    $subscriptionId: ID!
    $duration: Int!
    $durationUnit: DurationUnit!
  ) {
    renewalEstimate(
      subscriptionId: $subscriptionId
      duration: $duration
      durationUnit: $durationUnit
      includeTaxesInFees: true
    ) {
      lineItems {
        name
        amount
        category
      }
      total
    }
  }
`

export default function useSubscriptionEstimate({
  subscriptionId,
  subscriptionTerm,
  mileageTerm,
  vehicleId,
  duration,
  durationUnit,
  promotionalCodeId,
}) {
  const { isLoggedIn } = useAppState()
  const isRenewal = !!subscriptionId
  // MOBILE BACKEND CURRENTLY REQUIRES THIS FOR ESTIMATE, BUT NO LONGER USES IT
  const [pickupDate] = useState(new Date())

  const subscriptionEstimateQuery = useQuery(SUBSCRIPTION_ESTIMATE, {
    variables: {
      vehicleId: vehicleId,
      pickupDate,
      duration,
      durationUnit,
      termMonths: subscriptionTerm?.term,
      termMileage: mileageTerm?.mileage,
      promotionalCodeId,
    },
    skip: isRenewal || !vehicleId || !isLoggedIn,
  })

  const renewalEstimateQuery = useQuery(RENEWAL_ESTIMATE, {
    variables: {
      subscriptionId,
      duration,
      durationUnit,
    },
    skip: !isRenewal || !isLoggedIn,
  })

  const data =
    get(subscriptionEstimateQuery, 'data.subscriptionEstimate') ||
    get(renewalEstimateQuery, 'data.renewalEstimate')

  return {
    loading: subscriptionEstimateQuery.loading || renewalEstimateQuery.loading,
    error: subscriptionEstimateQuery.error || renewalEstimateQuery.error,
    data,
    refetch: !isRenewal
      ? subscriptionEstimateQuery.refetch
      : renewalEstimateQuery.refetch,
  }
}
